import {ExperimentNames} from '@wix/wix-events-commons-statics'
import {useEnvironment, useExperiments} from '@wix/yoshi-flow-editor'
import classNames from 'classnames'
import React from 'react'
import {Animated} from '../../../../../../../commons/components/animated'
import {useVisibilityStyles} from '../../../../hooks/use-visibility-styles'
import {shouldShowCountdown} from '../../../../selectors/layout'
import classesV2 from '../../../classes-v2.scss'
import {Countdown} from '../../../countdown'
import {AdditionalInfoFullDateLocation} from '../../../full-date-location'
import {Ribbon} from '../../../ribbon'
import {RsvpButton} from '../../../rsvp-button'
import {Date} from '../date'
import {Description} from '../description'
import {Image} from '../image'
import {Members} from '../members'
import {Share} from '../share'
import {Title} from '../title'
import {TitleLocation} from '../title-location'
import s from './desktop-item.scss'
import {ItemProps, TitleComponentProps} from './interfaces'

export const DesktopItem = ({
  t,
  event,
  opened,
  hovered,
  showRibbon,
  showMembers,
  isSite,
  scheduleTbd,
  rtl,
}: ItemProps) => {
  const {
    allBreakpoints: {isListCountdownEnabled},
  } = useVisibilityStyles()
  const {isEditor} = useEnvironment()
  const {experiments} = useExperiments()

  const active = opened || hovered
  const showCountdown = shouldShowCountdown(event)
  const newImageSettingsEnabled = experiments.enabled(ExperimentNames.NewImageSettings)

  return (
    <>
      <div className={s.collapsedContainer}>
        <div className={s.leftPositioning}>
          <div className={s.left}>
            <div
              className={classNames(s.imageContainer, {
                [s.disableTransitions]: newImageSettingsEnabled && isEditor,
                [s.collapsed]: active,
                [s.opened]: newImageSettingsEnabled && opened,
                [s.ltr]: !rtl,
              })}
            >
              <div className={s.image}>
                <Image event={event} />
              </div>
            </div>
            <div className={s.titleAndDateContainer}>
              {showRibbon && (
                <Ribbon event={event} className={classNames(s.ribbon, {[s.overrideMargin]: !showCountdown})} />
              )}
              {isListCountdownEnabled() && showCountdown && (
                <div className={s.countdown}>
                  <Countdown event={event} />
                </div>
              )}
              <a tabIndex={0} aria-expanded={opened} role="button" className={s.titleAndDate}>
                <Date scheduleTbd={scheduleTbd} event={event} />
                <TitleComponent event={event} hovered={hovered} opened={opened} />
              </a>
            </div>
          </div>
        </div>
        <RsvpButton event={event} minWidth={100} maxWidth={200} />
      </div>
      <div className={s.additionalInfo}>
        <Animated disabled={!isSite} height={calculateContentHeight} show={opened}>
          <div className={s.textContent}>
            <AdditionalInfoFullDateLocation
              event={event}
              dateClassName={classesV2.hoverEventDate}
              locationClassName={classesV2.hoverEventLocation}
            />
            <Description event={event} />
            <Members event={event} visible={showMembers} />
            <Share t={t} event={event} />
          </div>
        </Animated>
      </div>
    </>
  )
}

const TitleComponent = ({event, hovered, opened}: TitleComponentProps) =>
  hovered || opened ? <Title event={event} opened={opened} withOverflow={!opened} /> : <TitleLocation event={event} />

const calculateContentHeight = (element: HTMLElement) => element.offsetHeight + 60
